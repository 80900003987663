import React, { useEffect, useState } from 'react';

import { useGetOne } from 'react-admin';

import {
  Card,
  CardContent,
  Box,
  CardHeader,
  LinearProgress,
  Typography,
} from '@mui/material';

import ReactApexChart from 'react-apexcharts';
import { isEmpty } from 'lodash';
import SelectInputButton from '../../layout/inputFields/SelectInputButton';

const useGetGraphData = (
  data: {
    numberCasesByStatus: {
      Open: number;
      Closed: number;
      'in-progress': number;
      pending: number;
    }
  },
) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ReactApexChart['props']['options']>();
  const [series, setSeries] = useState<ReactApexChart['props']['series']>();

  useEffect(() => {
    if (!data) return;

    const newSeries = [
      data.numberCasesByStatus.Open ?? 0,
      data.numberCasesByStatus['in-progress'] ?? 0,
      data.numberCasesByStatus.pending ?? 0,
      data.numberCasesByStatus.Closed ?? 0,
    ] as ReactApexChart['props']['series']; // satisfies ReactApexChart['props']['series']  when ts is upgraded;

    const newOptions = {
      colors: ['#FF4242', '#FFA500', '#FFFF00', '#238061'],
      labels: ['Open', 'In progress', 'Pending', 'Closed'],
      chart: {
        redrawOnParentResize: true,
        height: 400,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },

      },
      legend: {
        position: 'bottom',
        show: !isEmpty(data.numberCasesByStatus),
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                color: '#124131',
                label: 'Total Cases',
              },
            },
          },
        },
      },
    } as ReactApexChart['props']['options']; // satisfies ReactApexChart['props']['options'] when ts is upgraded;

    setHasData(true);
    setOptions(newOptions);
    setSeries(newSeries);
  }, [data]);

  return {
    hasData,
    options,
    series,
  };
};

const CasesAnalytics = () => {
  const [period, setPeriod] = useState('30');
  const { data, isLoading } = useGetOne('dashboard', { id: `cases/${period}` });

  const { hasData, options, series } = useGetGraphData(data);

  return (
    <Card
      sx={{
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        height: '100%',
      }}
    >
      <CardHeader
        title="Cases"
        action={(
          <SelectInputButton
            choices={[
              { id: '1', name: 'Last day' },
              { id: '7', name: 'Last week' },
              { id: '30', name: 'Last month' },
              { id: '90', name: 'Last quarter' },
              { id: '365', name: 'Last year' },
              { id: '99999', name: 'All time' },
            ]}
            value={period}
            setValue={setPeriod}
          />
        )}
      />
      <CardContent
        sx={{
          flexGrow: 1,
        }}
      >
        {isLoading && <LinearProgress /> }
        {hasData && <ReactApexChart options={options} series={series} type="donut" height={300} />}
        {(!isLoading && !hasData) && (
          <Box margin={10} width="100%">
            <Typography align="center"> No cases available </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CasesAnalytics;
