import React, { useMemo, useState } from 'react';

import {
  ReferenceArrayField,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  WrapperField,
  useRecordContext,
  RaRecord,
  RecordContextProvider,
  useRedirect,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

import useDialogStatus from '../../../hooks/useDialogStatus';

import ReferenceEntityField from '../../../customFields/ReferenceEntityField';
import StatusField from '../../../customFields/StatusField';
import TeamChip from '../../../customFields/ColoredChipField';

import BulkUpdatePings from '../bulkActions/BulkUpdatePings';
import UpdatePingStatus from '../../ping/components/UpdatePingStatus';
import Pagination from '../../layout/Pagination';
import LinkField from '../../layout/LinkField';

import { boldDataGridStyle } from '../../../constants/style/datagridStyles';
import pingStatuses from '../../../constants/pingStatuses';
import ColoredScoreField from '../../../customFields/ColoredScoreField';

const CasePingList = () => {
  const [disabled, setDisabled] = useState(true);
  const { open, openDialog, closeDialog } = useDialogStatus();
  const [havePendingPing, setHavePendingPing] = useState(false);
  const redirect = useRedirect();

  const record = useRecordContext();

  const pings = useMemo(() => record?.pings.map((ping: RaRecord) => ping.id), [record?.pings]);

  return (
    <Card variant="outlined">
      <CardHeader
        title="Pings"
        action={(
          <Box>
            <Button
              startIcon={<LibraryAddCheckIcon />}
              disabled={disabled}
              onClick={openDialog}
            >
              Bulk Handle
            </Button>
            <Button onClick={() => redirect('add-ping')} startIcon={<EmojiObjectsIcon />}>
              Create Ping
            </Button>
          </Box>
        )}
      />
      <Divider />
      <RecordContextProvider value={{ pings }}>
        <ReferenceArrayField
          perPage={10}
          pagination={<Pagination disableEmptyText />}
          reference="pings"
          source="pings"
        >
          <Datagrid
            sx={boldDataGridStyle}
            // eslint-disable-next-line react/jsx-no-useless-fragment
            bulkActionButtons={havePendingPing ? <></> : false}
            resource="pings"
            isRowSelectable={(row) => row?.status?.toLowerCase() === pingStatuses.PENDING}
          >
            <WrapperField label="Id">
              <BulkUpdatePings
                setDisabled={setDisabled}
                setHavePendingPing={setHavePendingPing}
                open={open}
                closeDialog={closeDialog}
              />
              <LinkField sortable={false} source="id" linkType="show" />
            </WrapperField>
            <ArrayField sortable={false} source="entities" label="Entities">
              <SingleFieldList linkType={false}>
                <ReferenceEntityField source="" chip />
              </SingleFieldList>
            </ArrayField>
            <ReferenceArrayField sortable={false} source="tags" reference="tags" label="Tags" emptyText="-">
              <SingleFieldList placeholder="-">
                <TeamChip />
              </SingleFieldList>
            </ReferenceArrayField>
            <TextField sortable={false} source="description" />
            <StatusField sortable={false} source="status" />
            <UpdatePingStatus label="" source="status" />
            <ColoredScoreField source="score" />
          </Datagrid>
        </ReferenceArrayField>
      </RecordContextProvider>
    </Card>
  );
};

export default CasePingList;
