import { ValueOf } from '../utilities/types';

const casePriority = {
  LOW: 'low',
  NORMAL: 'normal',
  HIGH: 'high',
  URGENT: 'urgent',
} as const;

export type CasePriority = ValueOf<typeof casePriority>;

export default casePriority;
